import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import ScrollDivider from "../components/ScrollDivider";
import logo from "../img/logo.svg";
import FullWidthMedia from "../components/FullWidthMedia";

// eslint-disable-next-line
export const ThemaPageTemplate = ({
  title,
  heading,
  subheading,
  content,
  contentComponent,
  color,
}) => {
  const PageContent = contentComponent || Content;

  // const opts = {
  //   height: "480",
  //   width: "100%",
  //   playerVars: {
  //     // https://developers.google.com/youtube/player_parameters
  //     autoplay: 0,
  //     showinfo: 0,
  //     modestbranding: 1,
  //   },
  // };

  return (
    <section
      className="section section--gradient"
      style={{ background: color }}
    >
      <div className="container" id="main">
        <div className="columns is-multiline">
          <div className="column is-8 is-offset-2">
            <div className="section content has-text-centered">
              <img src={logo} alt="Dorsten" style={{ width: "220px" }} />

              <h2 className="title is-size-3">{heading}</h2>
              <PageContent className="theme-italic" content={content} />
            </div>
          </div>
          <div className="column is-10 is-offset-1">
            <div className="section content has-text-centered promote">
              {/* <YouTube videoId="hnz17mjemNE" opts={opts} /> */}
              <a
                href="http://www.youtube.com/watch?v=hnz17mjemNE"
                rel="noreferrer"
                target="_blank"
                className="promote-button center"
                style={{ backgroundColor: "#e3decc", color: "#00412d" }}
              >
                BEKIJK DE VIDEO
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

ThemaPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
  color: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
};

const ThemaPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <FullWidthMedia
        video={post.frontmatter.url}
        title={post.frontmatter.title}
        subheading={post.frontmatter.subheading}
        height={"90vh"}
        color="#E3DECC"
      />
      {/* <FullWidthImage
        title={post.frontmatter.title}
        subheading={post.frontmatter.subheading}
        height={"100vh"}
        color="#E3DECC"
      /> */}
      <ScrollDivider
        color={post.frontmatter.color}
        button={true}
        anchor="thema#main"
      />
      <ThemaPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
        color={post.frontmatter.color}
        heading={post.frontmatter.heading}
        subheading={post.frontmatter.subheading}
      />
    </Layout>
  );
};

ThemaPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ThemaPage;

export const themaPageQuery = graphql`
  query ThemaPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        color
        heading
        subheading
        url
      }
    }
  }
`;
